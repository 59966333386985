import { IconButton, Tooltip } from '@mui/joy';
import { useCallback, useState } from 'react';

const BUTTON_SX = {
  padding: 0,
  minHeight: 16,
  minWidth: 16,
  '&:hover': {
    backgroundColor: 'transparent'
  }
};

export const ToggleTooltip = ({
  tooltipContent,
  children,
  buttonProps,
  ariaLabel,
  ...props
}) => {

  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(
    () => setOpen((prev) => !prev),
    []
  );

  const {
    sx: providedButtonStyle,
    ...restButtonProps
  } = buttonProps || {};

  const buttonSX = {
    ...BUTTON_SX,
    ...providedButtonStyle
  };

  return (
    <Tooltip
      arrow
      open={open}
      variant={'plain'}
      placement={'top'}
      title={(
        { ...tooltipContent }
      )}
      {...props}
    >
      <IconButton
        onClick={toggleOpen}
        onBlur={() => setOpen(false)}
        sx={buttonSX}
        aria-label={ariaLabel}
        {...restButtonProps}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};
